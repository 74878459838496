<template>
  <div class="division-picker" v-if="isPickerVisible != null && isPickerVisible && currentDivsion">
    <b-tooltip label="Can't change division on editing page" position="is-bottom" :active="divisionPickerState == 'readonly'">
      <b-dropdown v-model="selectedDivision" :class="{'has-one-division': divisions.length == 1}"
                  @update:modelValue="divisionChanged" :disabled="divisionPickerState == 'readonly' || divisions.length == 1" :max-height="200" scrollable>
        <template #trigger>
          <button class="button is-primary is-outlined division-picker__button">
            <span class="icon" v-if="currentDivsion.id != '*'" >
              <span class="division-picker__color-icon" :style="{ backgroundColor: currentDivsion.color }"></span>
            </span>
            <span>{{ currentDivsion.name }}</span>
            <span class="icon" v-if="divisions.length > 1">
            <i class="mdi mdi-menu-down"></i>
          </span>
          </button>
        </template>
        <template #default v-if="divisions.length > 1">
          <b-dropdown-item v-for="(division, index) in divisions" :key="index" :value="division.id">
            <div class="media division-picker__dropdown-item">
              <span class="division-picker__color-icon" :style="{ backgroundColor: division.color }"></span>
              <div class="media-content">
                {{ division.name }}
              </div>
            </div>
          </b-dropdown-item>
        </template>
      </b-dropdown>
    </b-tooltip>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {debounce} from "@/lib/helpers";
import {getAvailableDivisionId} from "@/lib/Divisions";
import {EventBus, EVENTS} from "@/lib/EventBus";

const DIVISION_ID = 'DIVISION_ID'
export default {
  name: "DivisionPicker",
  methods: {
    async divisionChanged(divisionId) {
      if (this.divisionPickerState != 'visible') {
        return;
      }
      window.sessionStorage.setItem(DIVISION_ID, divisionId)
      const query = { ...this.$route.query }
      // resetting table pager if exists
      if (query.page) {
        query.page = 1
      }
      query.division_id = divisionId
      await this.$router.push({ query, force: true })
      this.$store.commit('setDivisionId', divisionId)
      EventBus.$emit(EVENTS.DIVISION_CHANGED, divisionId)
    },
    setIsVisible: debounce(function() {
      this.isPickerVisible = ['readonly', 'visible'].includes(this.divisionPickerState) && this.selectedDivision
    }, 100),
  },
  computed: {
    ...mapState(['divisionId', 'divisionPickerState']),
    divisions() {
      const divs = [], allDivisionsEnabled = this.$route.meta.allDivisionsEnabled;
      if (typeof allDivisionsEnabled === 'undefined' || allDivisionsEnabled) {
        divs.push({ id: '*', name: 'All hospitals' })
      }
      return divs.concat(this.$store.state.currentUser.divisions)
    },
    currentDivsion() {
      return this.divisions.find(x => x.id == this.selectedDivision)
    }
  },
  watch: {
    'divisionId': {
      handler(divisionId) {
        this.selectedDivision = +divisionId || divisionId
      },
      immediate: true
    },
    "$route": function({ query: { division_id }}) {
      if (division_id) {
        this.$store.commit('setDivisionId', division_id)
      }
    }
  },
  created() {
    this.$watch(() => {
      return [this.divisionPickerState, this.selectedDivision]
    }, () => {
      this.setIsVisible()
    }, { immediate: true });

    this.$watch('divisions', newVal => {
      // if currently selected division is no longer assigned to user
      if (!newVal.find(x => x.id == this.selectedDivision)) {
        this.selectedDivision = getAvailableDivisionId({ userObject: this.$store.state.currentUser })
        this.divisionChanged(this.selectedDivision)
      }
    })
  },
  data() {
    return {
      selectedDivision: null,
      isPickerVisible: null
    }
  }
}
</script>

<style scoped lang="scss">
  .division-picker__color-icon {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: transparent;
  }
  .division-picker :deep(.dropdown-menu) {
    z-index: 50;
    a.dropdown-item.is-active,
    .dropdown .dropdown-menu .has-link a.is-active,
    button.dropdown-item.is-active {
      background: $primary;
    }
  }
  .division-picker :deep(.tooltip-content) {
    z-index: 99;
  }
  .division-picker :deep(.dropdown.is-disabled.has-one-division) {
    opacity: 1 !important;
  }
  .division-picker__dropdown-item {
    display: flex;
    align-items: center;
    .division-picker__color-icon {
      margin-right: 10px;
      margin-left: -1px;
    }
  }
  .division-picker__button {
    border-radius: 20px;
  }
</style>
