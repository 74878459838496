import mitt from 'mitt'
const emitter = mitt()
export const EVENTS = Object.freeze({
  QUICKVIEW_VISIBILITY_CHANGE: 'QUICKVIEW_VISIBILITY_CHANGE',
  RELOAD_WIDGET_MONITOR_DATA: 'RELOAD_WIDGET_MONITOR_DATA',
  SIDEBAR_OPEN_STATE: 'SIDEBAR_OPEN_STATE',
  SIDEBAR_REDUCED_STATE: 'SIDEBAR_REDUCED_STATE',
  DIVISION_CHANGED: 'DIVISION_CHANGED',
  CARD_HOVER: 'CARD_HOVER',
  VIDEO_CONNECTED: 'VIDEO_CONNECTED',
  STOP_MONITORING: 'STOP_MONITORING',
  ROOM_MISMATCH: 'ROOM_MISMATCH',
});

export const EventBus = {
  $on: (...args) => emitter.on(...args),
  $once: (...args) => emitter.once(...args),
  $off: (...args) => emitter.off(...args),
  $emit: (...args) => emitter.emit(...args)
}
