export default {
  namespaced: true,
  state: {
    readOnlySafetyZoneEnabled: true,
    nurseStationEnabled: false,
    roomMismatchMonitors: new Set()
  },
  mutations: {
    setReadOnlySafetyZoneEnabled(state, value) {
      state.readOnlySafetyZoneEnabled = value
    },
    setNurseStationEnabled(state, value) {
      state.nurseStationEnabled = value
    },
    pushRoomMismatchMonitors(state, value) {
      state.roomMismatchMonitors.add(value)
    },
    deleteRoomMismatchMonitors(state, value) {
      state.roomMismatchMonitors.delete(value)
    }
  }
}
